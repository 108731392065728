exports["Dictionary"] = Dictionary;
exports["minify"] = minify;
exports["parse"] = parse;
exports["push_uniq"] = push_uniq;
exports["OutputStream"] = OutputStream;
exports["TreeTransformer"] = TreeTransformer;
exports["TreeWalker"] = TreeWalker;
exports["string_template"] = string_template;
exports["Compressor"] = Compressor;
exports["defaults"] = defaults;
exports["base54"] = base54;
exports["mangle_properties"] = mangle_properties;
exports["reserve_quoted_keys"] = reserve_quoted_keys;
exports["to_ascii"] = to_ascii;
